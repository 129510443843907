import { getRating } from '@/helpers/statusIncident.js';
import { supportRequests } from '@/helpers/dummyData';
import TicketItem from '@/components/inventory/assets/AssetReturns/elements/ReturnItem/ReturnItem.vue';
import { mapActions, mapState } from 'vuex';
import { showError, hasItems } from '@/helpers/globalHelpers';
import { returnTicketBreadcrumb } from '@/helpers/listBreadcrumbs';
import { getIncidentStatus } from '@/helpers/statusIncident.js';
import moment from 'moment';

export default {
	name: 'ListReturns',
	data() {
		return {
			loading: false,
			searchTimeout: null,
			searchValue: '',
			dialog: false,
			breadcrumbs: returnTicketBreadcrumb,
			statusList: [
				{ text: 'SOLICITADO', value: 'SOLICITADO' },
				{ text: 'CERRADO', value: 'CERRADO' },
			],
			headers: [
				{ text: 'Ticket', sortable: false, value: 'num_ticket' },
				{ text: 'Descripción', sortable: false, value: 'description' },
				{ text: 'Fecha de creación', sortable: false, value: 'created_at' },
				{ text: 'Asignado', sortable: false, value: 'assigned_user_id.fullName' },
				{ text: 'Estado', sortable: false, value: 'status' },
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
			// areaList: [
			// 	{ text: 'TI', id: 2 },
			// 	{ text: 'Legal', id: 1 },
			// 	{ text: 'Finanzas', id: 4 },
			// ],
			ticketfilters: {
				page: 1,
				limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
				pagination: true,
				num_ticket: null,
				status: null,
				generic: true,
				//q: '',
			},
			// filters: {
			// 	q: null,
			// 	page: 1,
			// 	area: null,
			// 	status: null,
			// 	pagination: true,
			// 	per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
			// },
			supportRequests: supportRequests,
		};
	},

	created() {
		this.getTickets();
	},

	methods: {
		...mapActions('ticket', ['listTicket', 'cleanTickets']),
		getRating,
		hasItems,
		getIncidentStatus,
		searchTickets() {
			this.ticketfilters.page = 1;
			this.getTickets();
		},

		async getTickets() {
			this.loading = true;
			const { error } = await this.listTicket(this.ticketfilters);
			if (error) showError(error);
			this.loading = false;
		},
		debounceSearch() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.ticketfilters.num_ticket = this.searchValue;
				this.getTickets();
			}, 800);
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},
	},

	beforeDestroy() {
		this.cleanTickets();
	},
	destroyed() {
		clearTimeout(this.timeout);
	},

	components: {
		TicketItem,
	},

	computed: {
		...mapState('ticket', [
			'tickets',
			'ticketsPagination',
			'loadingTicket',
			'filtersTicket',
		]),
	},
};
