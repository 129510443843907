const incidentList = [
	{
		id: 1,
		ticket: '#101',
		area: 'Legal',
		user: 'Juan Carlos Gonzales',
		created_at: '13/10/2022',
		updated_at: '13/10/2022',
		status: 'Cerrado',
		flagStatus: true,
		qualification: 5,
	},
	{
		id: 2,
		ticket: '#102',
		area: 'TI',
		user: 'Cesar Vereau',
		created_at: '30/01/2023',
		updated_at: '30/01/2023',
		status: 'Cerrado',
		flagStatus: true,
		qualification: 4,
	},
	{
		id: 3,
		ticket: '#103',
		area: 'Finanzas',
		user: 'Cristhian Quispe',
		created_at: '08/04/2023',
		updated_at: '08/04/2023',
		status: 'Cerrado',
		flagStatus: false,
		qualification: 2,
	},
];

const supportRequests = [
	{
		id: 1,
		user: 'Omar',
		date: '04/03/2023',
		nroTicket: '028329',
		description: 'Celular no enciende pantalla',
		status: 'Nuevo',
	},
	{
		id: 2,
		user: 'Juan Carlos',
		date: '04/03/2023',
		nroTicket: '028328',
		description: 'Celular no enciende pantalla',
		status: 'En progreso',
	},
	{
		id: 3,
		user: 'Carlos',
		date: '04/03/2023',
		nroTicket: '028327',
		description:
			'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen.',
		status: 'En progreso',
	},
];

const commentsList = [
	{
		user: 'José Sanchez.',
		comment: '¿En que puedo ayudarte?, comentame',
		date: '16/03/2023 12:00pm',
		manager: true,
	},
	{
		user: 'Rafael Berrospi.',
		comment:
			'Tengo un problema con mi equipo, el sistema operativo se lleno de virus aparentemente y ahora no enciende',
		date: '16/03/2023 12:00pm',
		manager: false,
	},
	{
		user: 'José Sanchez.',
		comment: '¿En que puedo ayudarte?, comentame',
		date: '16/03/2023 12:00pm',
		manager: true,
	},
	{
		user: 'Rafael Berrospi.',
		comment:
			'Tengo un problema con mi equipo, el sistema operativo se lleno de virus aparentemente y ahora no enciende',
		date: '16/03/2023 12:00pm',
		manager: false,
	},
	{
		user: 'José Sanchez.',
		comment: '¿En que puedo ayudarte?, comentame',
		date: '16/03/2023 12:00pm',
		manager: true,
	},
	{
		user: 'Rafael Berrospi.',
		comment:
			'Tengo un problema con mi equipo, el sistema operativo se lleno de virus aparentemente y ahora no enciende',
		date: '16/03/2023 12:00pm',
		manager: false,
	},
];

export { incidentList, supportRequests, commentsList };
