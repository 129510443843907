<template>
	<list-returns />
</template>
<script>
import ListReturns from '@/components/inventory/assets/AssetReturns/ListReturns/ListReturns.vue';

export default {
	name: 'ListReturnsPage',
	components: { ListReturns },
};
</script>
