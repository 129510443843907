import moment from 'moment';
import { getRating } from '@/helpers/statusIncident.js';
import { getIncidentStatus } from '@/helpers/statusIncident.js';

export default {
	name: 'ReturnItem',
	props: {
		value: Boolean,
		request: Object,
	},
	data() {
		return {};
	},

	methods: {
		getRating,
		getIncidentStatus,
		changeFilter() {},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},
	},
};
